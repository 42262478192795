import { Box, BoxProps } from "@chakra-ui/react"

export default function HeadingText({ children, ...props }: BoxProps) {
  return (
    <Box
      textAlign={"center"}
      color={"brand.black"}
      fontSize={"xl"}
      pt={10}
      px={{ base: 0, md: 24, xl: 36 }}
      {...props}
    >
      {children}
    </Box>
  )
}
