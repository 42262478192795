import { Box, BoxProps } from "@chakra-ui/react"
import { useRouter } from "next/router"

export default function StylizedHeading(props: BoxProps) {
  const { locale } = useRouter()

  return (
    <Box
      as={"h3"}
      fontFamily={locale === "ar" ? "Arslan Wessam" : "Wild Spirit Alt"}
      textAlign={"center"}
      mt={{ base: -8, md: -12, lg: -16 }}
      color={"brand.gray"}
      fontSize={{ base: "6xl", md: "8xl", lg: "9xl" }}
      {...props}
    >
      {props.children}
    </Box>
  )
}
