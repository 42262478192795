import { Logo } from "@definitions/types"
import AlTayerGroup from "@public/images/logos/groups/Al-Tayer-Group.png"
import AlDarGroup from "@public/images/logos/groups/Aldar-Group.png"
import ApparelGroup from "@public/images/logos/groups/Apparel-Group.png"
import Binghatti from "@public/images/logos/groups/Binghatti.png"
import Emirates from "@public/images/logos/groups/Emirates.png"
import JumeirahGroup from "@public/images/logos/groups/Jumeirah-Group.png"
import Lavazza from "@public/images/logos/groups/Lavazza.png"
import Mado from "@public/images/logos/groups/Mado.png"
import Marhaba from "@public/images/logos/groups/Marhaba.png"
import Marriott from "@public/images/logos/groups/Marriott-Hotels.png"
import CheckoutCom from "@public/images/logos/partners/checkout.com.png"
import Deliverect from "@public/images/logos/partners/Deliverect.png"
import Foodics from "@public/images/logos/partners/Foodics.png"
import Grubtech from "@public/images/logos/partners/Grubtech.png"
import NetworkInternational from "@public/images/logos/partners/Network-International.png"
import PayTabs from "@public/images/logos/partners/PayTabs.png"
import PointCheckout from "@public/images/logos/partners/Point-Checkout.png"
import Quiqup from "@public/images/logos/partners/Quiqup.png"
import SkipCash from "@public/images/logos/partners/SkipCash.png"
import Syrve from "@public/images/logos/partners/Syrve.png"
import Tevalis from "@public/images/logos/partners/Tevalis.png"
import XpresspayNigeria from "@public/images/logos/partners/Xpresspay-Nigeria.png"
export const GroupLogos: Logo[] = [
  {
    image: AlTayerGroup,
    name: "Al Tayer Group",
  },
  {
    image: AlDarGroup,
    name: "Al Dar Group",
  },
  {
    image: ApparelGroup,
    name: "Apparel Group",
  },
  {
    image: Binghatti,
    name: "Bin Ghatti",
  },
  {
    image: Emirates,
    name: "Emirates",
  },
  {
    image: JumeirahGroup,
    name: "Jumeirah Group",
  },
  {
    image: Lavazza,
    name: "Lavazza",
  },
  {
    image: Mado,
    name: "Mado",
  },
  {
    image: Marhaba,
    name: "Marhaba Lounge",
  },
  {
    image: Marriott,
    name: "Marriott HOtels",
  },
]

export const PartnerLogos: Logo[] = [
  {
    image: CheckoutCom,
    name: "Checkout.com",
  },
  {
    image: Deliverect,
    name: "Deliverect",
  },
  {
    image: Foodics,
    name: "Foodics",
  },
  {
    image: Grubtech,
    name: "Grubtech",
  },
  {
    image: Syrve,
    name: "Syrve",
  },
  {
    image: NetworkInternational,
    name: "Network International",
  },
  {
    image: PayTabs,
    name: "PayTabs",
  },
  {
    image: PointCheckout,
    name: "Point Checkout",
  },
  {
    image: Quiqup,
    name: "Quiqup",
  },
  {
    image: SkipCash,
    name: "SkipCash",
  },
  {
    image: Tevalis,
    name: "Tevalis",
  },
  {
    image: XpresspayNigeria,
    name: "Xpresspay Nigeria",
  },
]
